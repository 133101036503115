<template>
  <FormFieldWrap
    class="bf-input"
    v-bind="$props"
  >
    <el-input
      :name="name"
      type="password"
      v-bind="$attrs"
      @input="onInput"
      v-on="$listeners"
    />
    <template
      v-if="passwordStrength && !error"
      v-slot:user-feedback
    >
      <div :class="['password-strength', passwordStrength]">
        {{ passwordStrength | capitalize }} Password
      </div>
    </template>
  </FormFieldWrap>
</template>
<script>
import FormFieldWrap from '../FormFieldWrap/FormFieldWrap'
import zxcvbn from 'zxcvbn'

export default {
  name: 'BfInput',
  components: {
    FormFieldWrap
  },
  props: {
    ...FormFieldWrap.props,
    name: {
      type: String,
      default: 'password'
    },
    subtext: {
      type: String,
      default: 'Use at least 8 characters with a combination of uppercase and lowercase letters, numbers and symbols.'
    }
  },
  data: () => ({
    passwordStrength: null
  }),
  methods: {
    onInput(value) {
      if (value.length === 0) {
        this.passwordStrength = null
      } else if (zxcvbn(value).score >= 3) {
        this.passwordStrength = 'strong'
      } else {
        this.passwordStrength = 'weak'
      }
    }
  }
}
</script>

<style lang="scss">
  .password-strength {
    @include text-style('interface', 'extra-small', 'medium');
    color: $error;

    &.strong {
      color: $success;
    }
  }
</style>
